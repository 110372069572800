import React, { useState, useEffect } from 'react';
import {fetchUserReferLink} from "../../api/action"
import ToastMsg from '../../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import { Loader } from '../../common/Loader';
const ReferNowSection = ({refer}) => {
  // console.log(refer, "howitworks");
  
  const [error, setError] = useState(null);
  const [copiedError, setCopiedError] = useState(null);
  const [inprogressError, setInprogressError] = useState(null);
  
  const [referLinkData, setReferLinkData] = useState(null);
  const [extractedrefer, setExtractedrefer] = useState(null);
  const [claimError, setClaimError] = useState(false);
  const [ClaimErrormsg, setClaimErrormsg] = useState(null);
  const [loader, setLoader] = useState(false);

  
  
  
  
  const handleReferLink = () => {
    setLoader(true)
      fetchUserReferLink()
  .then((data) => {
   
    setLoader(false)
    setReferLinkData(data);
    const referrerMatch = data?.referral_link?.match(/referrer=([^&]*)/);
    const referrerValue = referrerMatch ? referrerMatch[1] : null;
    setExtractedrefer(referrerValue)
    // console.log(referrerValue, "Extracted Referrer");
    // console.log(data?.referral_link, "datasjkdhfkj");
  })
  .catch((err) => {
    const errorMsg = err?.message || 'Error fetching user refer link';
    ToastMsg(errorMsg, 'error');
    setLoader(false)
    
    
    console.log("Error fetching user refer link:", err);
  });

  };

  useEffect(() => {
   
    handleReferLink()
    const checkError = () => {
      const storedError = localStorage.getItem('error');
      const copiedError = localStorage.getItem('copiedtoclipboard')
      const inprogressError = localStorage.getItem('inprogressError')
      const ClaimError = localStorage.getItem('claim')
      const ClaimErrormsg = localStorage.getItem('claimerror')
      setError(storedError);
      setCopiedError(copiedError)
      setInprogressError(inprogressError)
      setClaimError(ClaimError)
      setClaimErrormsg(ClaimErrormsg)
    };

    checkError();

    const intervalId = setInterval(checkError, 1000);

    
    
    return () => clearInterval(intervalId);
  }, []); 

  // const tncmixpanel = ()=>{
  //   const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
  //   mixpanel.identify(mixpanelUserId)
  //   mixpanel.track("Interacted_PERP_Dashboard", {
  //     "Clicked on": "T&C",
  //   });
  // }


  const tncmixpaneltnc = () => {
    // alert("1")
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    
    const refer = true; 
    if (refer) {
      mixpanel.track("Interacted_PERP_How_It_Works", {
        "Clicked on": "T&C",
      });
    } else {
      mixpanel.track("Interacted_PERP_Dashboard", {
        "Clicked on": "T&C",
      });
    }
  };
  

  function openNativeShare(referLinkData) {
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    mixpanel.track("Interacted_PERP_Dashboard", {
      "Clicked on": "Refer now",
    });
  
    const REFER_WEB_DOMAIN_ENDPOINT = `${process.env.REACT_APP_REFER_WEB_DOMAIN || 'https://refer.paymeindia.in'}`;

    
    console.log(REFER_WEB_DOMAIN_ENDPOINT, "endpoint");
    
   let platformSpecificLink = `${REFER_WEB_DOMAIN_ENDPOINT}/refer_link?referrer=${extractedrefer}`;

  
   
  
    const shareData = {
      title: "Check out PaymeIndia!",
      text: `Hey, I’m using PayMe for instant, hassle-free personal loans. Use this link to download the app`,
      url: platformSpecificLink,
    };
  
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error sharing:", error);
        });
    } else if (window.Android) {
      window.Android.shareText(shareData.title, shareData.text, shareData.url);
      return;
    } else {
      alert("Your browser does not support the native share feature.");
    }
  }
  

  return (
    <>
    
    
      <div className="refer-now-section">
   


      { inprogressError && 
      <div className="error-message">
        <span className="icon">!</span>
        <span className="message-text">Your claim request is already in process.</span>
      </div>}

      { copiedError && 
      <div className="error-message-copied">
        {/* <span className="icon">!</span> */}
        <p className="copied-notification copiedtext">Copied to clipboard</p>
      </div>}

      
        
        { error && 
      <div className="error-message">
        <span className="icon">!</span>
        <span className="message-text">Minimum <span className='paymecolour'>1000 Coins</span>  required to claim</span>
      </div>}
    <div className='referNowSection'>
      
      <button onClick={() => openNativeShare(referLinkData?.referral_link)} className="refer-btn">Refer Now</button>
      <span  className='paymecolour termsandcondition'>

  <a onClick={tncmixpaneltnc} href="https://www.paymeindia.in/terms/" target="_blank" rel="noopener noreferrer">
    Terms & Conditions
  </a>
</span>


    </div>
    </div>
    </>
  );
};

export default ReferNowSection;
