// src/routes/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import HowItWorks from '../pages/HowItWorks'
import ViewAllTransactions from '../pages/ViewAllTransactions';
import ReferPage from '../pages/ReferPage';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/refer_link" element={<ReferPage />} />
        <Route path="/view-all-transactions" element={<ViewAllTransactions />} />

      </Routes>
    </Router>
  );
};

export default AppRouter;
