import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ReferPage = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referrer = searchParams.get('referrer');

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod|Mac/i.test(userAgent);
  
    let platformSpecificLink = `https://refer.paymeindia.in/refer_link?referrer=${referrer}`;
  
    if (isAndroid) {
      platformSpecificLink = `https://play.google.com/store/apps/details?id=io.attabot.app.paymeindia&referrer=${referrer}`;
    } else if (isIOS) {
      platformSpecificLink = `https://web.paymeindia.in/?referrer=${referrer}`;
    } else {
      platformSpecificLink = `https://web.paymeindia.in/?referrer=${referrer}`;
    }

    window.location.href = platformSpecificLink;
  }, [location.search]);

  return null; 
};

export default ReferPage;
